import React from 'react'
import Signin from '../components/Signin'


const signInPage = (props: any) => {
    return(
        <div>
            <Signin/>
        </div>
    )
}


export default signInPage