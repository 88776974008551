import * as Yup from 'yup'

//Esquema para validar la función de identificación
const SigninSchema = Yup.object().shape({
    email: Yup.string()
        .email('Email invalido')
        .required('Requerido'),
    hash_pwd: Yup.string()
        .min(2, 'demasiado corto')
        .max(50, 'Demasiado largo')
        .required('Requerido'),
});


export default SigninSchema;