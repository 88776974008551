import React from 'react'
import BusinessCreate from '../components/BusinessCreate'


const BusinessFormPage = (props: any) => {
    return(
        <div>
            <BusinessCreate/>
        </div>
    )
}


export default BusinessFormPage