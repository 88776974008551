import React, { useState } from 'react'

import { Button } from '@material-ui/core'
import Checkbox from '@material-ui/core/Checkbox'
import CloseIcon from '@mui/icons-material/Close'
import Grid from '@material-ui/core/Grid'
import SaveIcon from '@material-ui/icons/Save'
import SelectMeasureProduct from '../SelectMeasureProduct/SelectMeasureProduct'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import TextareaAutosize from '@mui/material/TextareaAutosize'
import TextField from '@material-ui/core/TextField'

import Input from '@material-ui/core/Input'
import ProductPictures from '../ProductPictures/ProductPictures'

interface IProduct {
    availability: boolean;
    productName: string;
    category : string;
    price: number;
    measure: string;
    image: string;
    description: string;
} 

type Prop = {
    editFormData: IProduct
    handleEditFormChange: any
    handleEditFormSubmit: any
    cancelEdition: any
}


const EditableRow: React.FC<Prop> = ({ editFormData, handleEditFormChange, handleEditFormSubmit, cancelEdition }) => {

    return (
        <TableRow key={editFormData.productName}>
              <TableCell style={{ width: "10%" }} align="left">
                  {<Checkbox checked={editFormData.availability} name="availability" onChange={handleEditFormChange}/>}
              </TableCell>
              <TableCell style={{ width: 160 }} align="left">
                  {editFormData.productName}
              </TableCell>
              <TableCell style={{ width: 160 }} align="left">
                  {editFormData.category}
              </TableCell>
              <TableCell style={{ width: 160 }} align="left">
                <TextField
                    type="text"
                    placeholder="Introduce la descripción"
                    multiline
                    name="description"
                    onChange={handleEditFormChange}
                    value={editFormData.description}
                >
                </TextField>
              </TableCell>
              <TableCell style={{ width: 160 }} align="left">
                <Grid container spacing={2} direction="row" justifyContent="flex-start" alignItems="center">
                    <Grid item xs={9}>
                        <Input
                            type="number"
                            placeholder="Introduce el precio"
                            inputProps={{ min:0 }}
                            name="price"
                            onChange={handleEditFormChange}
                            value={editFormData.price}
                        />
                    </Grid>
                </Grid>
            </TableCell>
              <TableCell style={{ width: "10%" }} align="left">
                <SelectMeasureProduct editFormData={editFormData} handleEditFormChange={handleEditFormChange}/>
              </TableCell>
              <TableCell style={{ width: 160 }} align="left">
                <ProductPictures productImage={editFormData.image} widthImage={"110px"} heightImage={"100px"}/>
                <Input id="selectPicture" type="file" name="image" onChange={handleEditFormChange}/>
              </TableCell>
              <TableCell style={{ width: "5%" }} align="left">
                    <Button onClick={handleEditFormSubmit}>
                        <SaveIcon fontSize="small"/>
                    </Button>
                    <Button onClick={cancelEdition}>
                        <CloseIcon fontSize="small"/>
                    </Button>
              </TableCell>
        </TableRow>
    )
}

EditableRow.propTypes = {
    
}

export default EditableRow