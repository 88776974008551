import React from 'react'
import PostalAddressSchema from '../schema/PostalAddressSchema'
import { apiPost } from '../../utils/api'
import { useHistory } from "react-router-dom"
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import { URL_INSERT_POSTAL_ADDRESS } from '../../utils/constants'
import { useSnackbarDispatchContext } from '../../contexts/SnackbarContext'
import { add } from '../../actions/Snackbar'
import { FormikHelpers, useFormik } from 'formik'
import * as yup from 'yup'
import Grid from '@material-ui/core/Grid'
import MenuItem from '@material-ui/core/MenuItem'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& .MuiTextField-root': {
        margin: theme.spacing(1),
        width: '30ch',
      },
      textAlign: "center"
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    prefix: {
        width: '12ch !important',
    },
    phone: {
        width: '17ch !important'
    }
  }),
);

type Prop = {
    nameBusiness: string
}
type PostalAddressData = yup.InferType<typeof PostalAddressSchema>
const PostalAddressCreate : React.FC<Prop> = (props) => {
    const history = useHistory()
    const classes = useStyles()
    const snackbarDispatch = useSnackbarDispatchContext()

    const sendForm = async (values: PostalAddressData, { setSubmitting } : FormikHelpers<PostalAddressData>) =>{
        apiPost(URL_INSERT_POSTAL_ADDRESS, values)                  
        //apiPost('http://s3-ceatic.ujaen.es:8013/api/postalAddress/insertPostalAddress', values)
        .then(data => {
            console.log(data)
            //TODO, pasando token
            history.push('/profile')
        })
        .catch(error => snackbarDispatch(add({ message: error.message, severity: "error"})))
    }

    const formik = useFormik({
        initialValues: {
            nameShop : "",
            streetAddress : "",
            postalCode : "",
            locality: "",
            country: "",
            email: "",
            pre_telephone: "",
            telephone: "",
            hash_pwd: "",
            language: "",
            nameBusiness: props.nameBusiness
        } as PostalAddressData,
        validationSchema: PostalAddressSchema,
        onSubmit: sendForm
    })

    return (
        <div className={classes.root}>
            <h1>Formulario para registrar una tienda</h1>
            <form onSubmit={formik.handleSubmit} className={classes.root} noValidate autoComplete="off">
                    <Grid>
                        <TextField 
                            label="Nombre de la tienda"
                            className="form-control"
                            name="nameShop"
                            placeholder=""
                            type="text"
                            variant="outlined"
                            value={formik.values.nameShop}
                            onChange={formik.handleChange}
                            error={formik.touched.nameShop && Boolean(formik.errors.nameShop)}
                            helperText={formik.touched.nameShop && formik.errors.nameShop}
                        ></TextField>
                    </Grid>
                    <Grid>
                        <TextField 
                            className="form-control"
                            name="streetAddress"
                            placeholder=""
                            type="text"
                            label="Direccion"
                            variant="outlined"
                            value={formik.values.streetAddress}
                            onChange={formik.handleChange}
                            error={formik.touched.streetAddress && Boolean(formik.errors.streetAddress)}
                            helperText={formik.touched.streetAddress && formik.errors.streetAddress}
                        ></TextField>
                    </Grid>
                    <Grid>
                        <TextField 
                            className="form-control"
                            name="postalCode"
                            placeholder=""
                            type="text"
                            label="Codigo postal"
                            variant="outlined"
                            value={formik.values.postalCode}
                            onChange={formik.handleChange}
                            error={formik.touched.postalCode && Boolean(formik.errors.postalCode)}
                            helperText={formik.touched.postalCode && formik.errors.postalCode}
                        ></TextField>
                    </Grid>
                    <Grid>
                        <TextField 
                            className="form-control"
                            name="locality"
                            placeholder=""
                            type="text"
                            label="Localidad"
                            variant="outlined"
                            value={formik.values.locality}
                            onChange={formik.handleChange}
                            error={formik.touched.locality && Boolean(formik.errors.locality)}
                            helperText={formik.touched.locality && formik.errors.locality}
                        ></TextField>
                    </Grid>
                    <Grid>
                        <TextField 
                            name="country" 
                            select
                            label="País"
                            variant="outlined"
                            value={formik.values.country}
                            onChange={formik.handleChange}
                            error={formik.touched.country && Boolean(formik.errors.country)}
                            helperText={formik.touched.country && formik.errors.country}>
                            <MenuItem key="ES" value="ES">
                                España
                            </MenuItem>
                            <MenuItem key="EN" value="EN">
                                England
                            </MenuItem>
                        </TextField>
                    </Grid>
                    <Grid>
                        <TextField 
                            className="form-control"
                            name="email"
                            placeholder=""
                            type="email"
                            label="Email"
                            variant="outlined"
                            value={formik.values.email}
                            onChange={formik.handleChange}
                            error={formik.touched.email && Boolean(formik.errors.email)}
                            helperText={formik.touched.email && formik.errors.email}
                        ></TextField>
                    </Grid>
                    <Grid>
                        <TextField 
                            className={classes.prefix}
                            name="pre_telephone" 
                            select
                            label="Prefijo"
                            variant="outlined"
                            value={formik.values.pre_telephone}
                            onChange={formik.handleChange}
                            error={formik.touched.pre_telephone && Boolean(formik.errors.pre_telephone)}
                            helperText={formik.touched.pre_telephone && formik.errors.pre_telephone}>
                                <MenuItem key="34" value="34">
                                    +34
                                </MenuItem>
                        </TextField>
                        <TextField 
                            className={classes.phone}
                            name="telephone"
                            placeholder=""
                            type="text"
                            label="Teléfono"
                            variant="outlined"
                            value={formik.values.telephone}
                            onChange={formik.handleChange}
                            error={formik.touched.telephone && Boolean(formik.errors.telephone)}
                            helperText={formik.touched.telephone && formik.errors.telephone}
                        ></TextField>
                    </Grid>
                    <Grid>
                        <TextField 
                            className="form-control"
                            name="hash_pwd"
                            placeholder=""
                            type="password"
                            label="Contraseña"
                            variant="outlined"
                            value={formik.values.hash_pwd}
                            onChange={formik.handleChange}
                            error={formik.touched.hash_pwd && Boolean(formik.errors.hash_pwd)}
                            helperText={formik.touched.hash_pwd && formik.errors.hash_pwd}
                        ></TextField>
                    </Grid>
                    <Grid>
                        <TextField 
                            name="language" 
                            select
                            label="Idioma"
                            variant="outlined"
                            value={formik.values.language}
                            onChange={formik.handleChange}
                            error={formik.touched.language && Boolean(formik.errors.language)}
                            helperText={formik.touched.language && formik.errors.language}>
                            <MenuItem key="es" value="es">
                                Español
                            </MenuItem>
                            <MenuItem key="en" value="en">
                                English
                            </MenuItem>
                        </TextField>
                    </Grid>
                    <br />
                    <Grid>
                            <Button
                            color="primary"
                            variant="contained"
                            className="mr-1 mb-1 btn-block"
                            type="submit"
                            >
                                Guardar
                            </Button>
                    </Grid>
                    <br />
            </form>
        </div>
    )
}

export default PostalAddressCreate



