//REVISAR TODO
import { logout } from '../actions/Session'
import { apiPost } from './api'
import { keyToken, keyEmail, URL_SIGNIN } from './constants'

export const authenticate = async (email: string, hash_pwd: string) => {
	const tokenRes = await apiPost(URL_SIGNIN, {email, hash_pwd})

	//await apiPost('http://s3-ceatic.ujaen.es:8013/api/auth/signin', {email, hash_pwd}).catch(error => {throw new Error("Error en token")})

	localStorage.setItem(keyToken, tokenRes)
	localStorage.setItem(keyEmail, email)
	//Guardar tambien correo
	return tokenRes
}


export const removeSession = async (dispatch : any) => {
    console.log('borrando token')
	localStorage.removeItem(keyToken)
	localStorage.removeItem(keyEmail)
	dispatch(logout())
}

export default removeSession