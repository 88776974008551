import React from 'react'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 90,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
  }),
);

interface IProduct {
  availability: boolean;
  productName: string;
  category : string;
  price: number;
  measure: string;
} 

const measureReal = [
  {
    value: 'kg',
    label: 'Kg',
  },
  {
    value: 'unit',
    label: 'Unidad',
  },
]

type Prop = {
  editFormData: IProduct
  handleEditFormChange: any
}

const SelectMeasureProduct: React.FC<Prop> = ({ editFormData, handleEditFormChange }) => {
    const classes = useStyles()

    return (
        <FormControl className={classes.formControl}>
            <Select
                native
                value={editFormData.measure}
                onChange={handleEditFormChange}
                label="Measure"
                name="measure"
                inputProps={{
                    name: 'measure',
                    id: 'select-for-measure',
                }}
                >
                <option value={measureReal[0].value}>{measureReal[0].label}</option>
                <option value={measureReal[1].value}>{measureReal[1].label}</option>
            </Select>
      </FormControl>
    )
}

SelectMeasureProduct.propTypes = {
    
}

export default SelectMeasureProduct