//Importación
import React from 'react';
import './App.css';
import {BrowserRouter as Router, Switch, Route, Link} from 'react-router-dom'
import MainPage from './pages/MainPage'
import BusinessPage from './pages/BusinessFormPage'
import PostalAddressPage from './pages/PostalAddressFormPage'
import SignInPage from './pages/SignInPage'
import ProfilePage from './pages/ProfilePage'
import ManagerCalendarPage from './pages/ManagerCalendarPage'
import ProductsPage from './pages/ProductsPage'
import PrivateRoute from './utils/PrivateRoute'
import Header from './components/Templates/Header'
import SessionContext from './contexts/SessionContext'
import SnackbarContext from './contexts/SnackbarContext'
import { Button } from '@material-ui/core'


//Generación de la función del componente
const App = () => {
  return (
    <SessionContext>
      <SnackbarContext>
        <Router>
          <Header/>
          <Switch>
            <Route exact path="/">
              <MainPage/>
            </Route>
            <Route exact path="/business">
              <BusinessPage/>
            </Route>
            <Route exact path="/signin">
              <SignInPage/>
            </Route>
            <PrivateRoute exact path="/profile">
              <ProfilePage/>
            </PrivateRoute>
            <PrivateRoute exact path="/scheduler">
              <ManagerCalendarPage/>
            </PrivateRoute>
            <PrivateRoute exact path="/managerCalendar">
              <ManagerCalendarPage/>
            </PrivateRoute>
            <PrivateRoute exact path="/products">
              <ProductsPage/>
            </PrivateRoute>
            <Route path="/business/:nameBusiness/postalAdress" 
                render={ props => <PostalAddressPage name={props.match.params.nameBusiness}/>} />
            <Route>
                <br/>
                <div style={{display: 'flex',  justifyContent:'center', alignItems:'center'}}>
                  <Button color="primary" component={Link} to="/" variant="contained">
                    Ir a la pantalla principal
                  </Button>
                  <br/>
              </div>
              <Link to="/"> </Link>
            </Route>
          </Switch>
        </Router>
      </SnackbarContext>
    </SessionContext>
    
  );
}

//Exportacion
export default App;
