import * as Yup from 'yup'

//Esquema para crear gr__BusinessEntity
const BusinessCreateSchema = Yup.object().shape({
    name: Yup.string()
        .min(2, 'demasiado corto')
        .max(50, 'Demasiado largo')
        .required('Requerido'),
    gr__category: Yup.string()
        .min(2, 'demasiado corto')
        .max(50, 'Demasiado largo')
        .required('Requerido'),
});


export default BusinessCreateSchema;