import React, { useState, useEffect } from 'react'
import { apiGet, apiPost } from '../../utils/api'

import { makeStyles, useTheme, Theme, createStyles } from '@material-ui/core/styles'

import ArrowDropDownRoundedIcon from '@material-ui/icons/ArrowDropDownRounded'
import ArrowDropUpRoundedIcon from '@material-ui/icons/ArrowDropUpRounded'
import axios from "axios"
import { Button } from '@material-ui/core'
import Checkbox from '@material-ui/core/Checkbox'
import EditableRow from '../EditableRow/EditableRow'
import EditIcon from '@material-ui/icons/Edit'
import FirstPageIcon from '@material-ui/icons/FirstPage'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormGroup from '@material-ui/core/FormGroup'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft'
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight'
import { keyEmail } from '../../utils/constants'
import LastPageIcon from '@material-ui/icons/LastPage'
import ProductPictures from '../ProductPictures/ProductPictures'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import SearchIcon from '@material-ui/icons/Search'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableFooter from '@material-ui/core/TableFooter'
import TableHead from '@material-ui/core/TableHead'
import TablePagination from '@material-ui/core/TablePagination'
import TableRow from '@material-ui/core/TableRow'

import DescriptionModal from '../DescriptionModal/DescriptionModal'
import { URL_GET_PRODUCT, URL_GET_CATEGORIES, URL_UPLOAD_PICTURES, URL_UPDATE_PRODUCTS } from '../../utils/constants'

type Prop = {

}

const useStyles1 = makeStyles((theme: Theme) =>
    createStyles({
      root: {
        flexShrink: 0,
        marginLeft: theme.spacing(2.5),
      },
    }),
)
  
interface IProduct {
    availability: boolean;
    productName: string;
    category : string;
    price: number;
    measure: string;
    image: string;
    description: string;
    file?: File;
}

const measureReal: {[key: string]: string} = {
  unit : "Unidad",
  kg : "Kg"
}

//Lista sera la que nos devuelva la base de datos 
let rows: IProduct[] = []

interface TablePaginationActionsProps {
    count: number;
    page: number;
    rowsPerPage: number;
    onPageChange: (event: React.MouseEvent<HTMLButtonElement>, newPage: number) => void
}

function TablePaginationActions(props: TablePaginationActionsProps) {
    const classes = useStyles1()
    const theme = useTheme()
    const { count, page, rowsPerPage, onPageChange } = props
  
    const handleFirstPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      onPageChange(event, 0)
    }
  
    const handleBackButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      onPageChange(event, page - 1)
    }
  
    const handleNextButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      onPageChange(event, page + 1)
    }
  
    const handleLastPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1))
    }
  
    return (
      <div className={classes.root}>
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="first page"
        >
          {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
          {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
        >
          {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="last page"
        >
          {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      </div>
    )
}

const useStyles2 = makeStyles({
    table: {
        minWidth: 500,
    },
})


//Variables booleanas para guardar si cuando se pulsen los botones de orden hay que ordenarlos de forma ascendente o descendente
let ascProductName = false
let ascPrice = true
let ascAvaliabity = true
let ascCategory = false

/**
 * Valores que puede tomar tipo de secuencia (typeSequence):
 * asPrice
 * desPrice
 * asProductName
 * desProductName
 * asAvaliable
 * desAvaliable
 * asCategory
 * desCategory
 */
let typeSequence: string = ""

const Products : React.FC<Prop> = () => {
  /**
   * Estados de nuestro componente
   * page 
   * rowsPerPage
   * newRows: lista de IProducts a la que le aplicamos los filtros, reglas de ordenación, etc que buscamos. 
   * search: estado que nos devuelve lo que se ha escrito en la barra de búsqueda 
   */
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(5)

  //Estado para tener la lista de busqueda por buscador de nombre
  const [search, setSearch] = useState<string>('')

  //Estado que maneja todas las categorías que tenemos (habrá que cambiarlo para que las coja de la base de datos)
  const [category, setCategory] = useState<string[]>([])
  
  //Estado para saber que categorías se han seleccionado para filtrar por familias
  const [selectCategory, setSelectCategory] = useState<string[]>([])
  
  //Estado del radioButton para filtrar por el criterio de disponiblidad
  const [valueRadioButton, setValueRadioButton] = useState('allProducts')

  //Estado que nos permite empezar la edición del precio de un producto
  const [editProduct, setEditProduct] = useState("")

  //Estado para cambiar el precio de un producto
  const [editFormData, setEditFormData] = useState<IProduct>({
    availability: false,
    productName: "",
    category : "",
    price: 0,
    measure: "Kg",
    image: "",
    description: ""
  })

  //Estado de la lista de productos que vamos a mostrar
  const [newRows, setRows] = useState<IProduct[]>(rows)

  //Estado para desplegar el menu de filtrado por categorias
  const [viewCategoryFilter, setViewCategoryFilter] = useState<boolean>(false)

  //Estado para desplegar el menu de filtrado por disponiblidad
  const [viewAvaliabilityFilter, setViewAvaliabilityFilter] = useState<boolean>(false)

  //Lista de formatos admitidos para subir imagenes de productos
  const formatPicture: string[] = [".jpeg", ".jpg", ".png"]

  useEffect(() => {
    if(rows.length == 0){
      apiGet(URL_GET_PRODUCT)
      .then(data => {
        data.map((product: IProduct) => {
          rows.push(product)
        })
        const prueba: IProduct[] = [...rows]
        setRows(prueba)
        
      }).catch(error => console.log(error))
    }

    apiGet(URL_GET_CATEGORIES)
      .then(data => {
        setCategory(data)
      }).catch(error => console.log(error))
  },[])

  
  /**
   * Switch que nos indica el tipo de secuencia de orden que aplicamos a nuestra lista de productos
   */
    switch (typeSequence) {
      case "asPrice":
        rows.sort((a, b) => (a.price < b.price ? -1 : 1))
        break
      case "desPrice":
        rows.sort((a, b) => (a.price < b.price ? 1 : -1))
        break
      case "asProductName":
        rows.sort((a, b) => (a.productName < b.productName ? -1 : 1))
        break
      case "desProductName":
        rows.sort((a, b) => (a.productName < b.productName ? 1 : -1))
        break
      case "asAvaliable":
        rows.sort((a, b) => (a.availability < b.availability ? 1 : -1))
        break
      case "desAvaliable":
        rows.sort((a, b) => (a.availability < b.availability ? -1 : 1))
        break
      case "asCategory":
        rows.sort((a, b) => (a.category < b.category ? -1 : 1))
        break
      case "desCategory":
        rows.sort((a, b) => (a.category < b.category ? 1 : -1))
        break
    }

    const classes = useStyles2()
    

    /**
     * Función que captura lo que escribimos en la barra de búsqueda. Modifica nuestro estado search con la cadena buscada. 
     * @param e evento que capturamos
     */
    const onChangeSearchField = (e: { persist: () => void; target: { value: { toString: () => React.SetStateAction<string> } } })=>{
      e.persist()
      setSearch(e.target.value.toString())
    }

    /**
     * Función de filtrado por nombre de los productos. Devuelve la lista con los que coinciden con la búsqueda.
     * Normalizamos para que nos devuelvan los objetos que coincidan obviando mayúsculas o minúsculas, incluso acentos. 
     * @returns Devuelve la lista de IProducts que cumplen las condiciones que buscamos
     */
    const filterAndPaginate = () => {
      //rows.filter estaba antes 
      let newDatos = newRows.filter(item => {
        if(item.productName.includes(search) || item.productName.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g,"").includes(search) ){
            return item
        }
      })

      newDatos = rowsPerPage > 0 ? newDatos.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : newDatos
      return newDatos
    }


    const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage)

    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        setPage(newPage)
    }

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,) => {
        setRowsPerPage(parseInt(event.target.value, 10))
        setPage(0)
    }

    /**
     * Función que ordena nuestra lista de productos IProducts por el nombre de producto.
     * Si es orden ascendente, ponemos a nuestra variable typeSequence como "asProductName", para que aplique ese orden
     * Si es orden descendente tomará el valor "desProductName" para que en el switch le aplique ese orden
     * Cambia ascProductName al valor de su negada, para que la próxima vez tome el orden inverso.
     */
    const sortProductName = () => {
        let sortedRows
        if(ascProductName){
          //Sería ascendente
          sortedRows = [...newRows].sort((a, b) => (a.productName < b.productName ? -1 : 1))
          typeSequence = "asProductName"
        }else{
          //Sería descendente
          sortedRows = [...newRows].sort((a, b) => (a.productName < b.productName ? 1 : -1))
          typeSequence = "desProductName"
        }
        setRows(sortedRows)
        //En la siguiente interacción conseguimos el orden inverso
        ascProductName = !ascProductName
    }
    
    /**
     * Función que ordena nuestra lista de productos IProducts por el precio del producto.
     * Si es orden ascendente, ponemos a nuestra variable typeSequence como "asPrice", para que aplique ese orden
     * Si es orden descendente tomará el valor "desPrice" para que en el switch le aplique ese orden
     * Cambia ascPrice al valor de su negada, para que la próxima vez tome el orden inverso.
     */
    const sortProductPrice = () => {
        let sortedRows
        if(ascPrice){
          //Sería ascendente 
          sortedRows = [...newRows].sort((a, b) => (a.price - b.price ))
          typeSequence= "asPrice"
        }else{
          //Sería descendete
          sortedRows = [...newRows].sort((a, b) => (b.price - a.price ))
          typeSequence= "desPrice"
        }
        setRows(sortedRows)
        ascPrice = !ascPrice
    }

    /**
     * Función que ordena nuestra lista de productos IProducts en función de si hay disponiblidad del producto o no.
     * Si es orden ascendente, ponemos a nuestra variable typeSequence como "asAvaliable", para que aplique ese orden
     * Si es orden descendente tomará el valor "desAvaliable" para que en el switch le aplique ese orden
     * Cambia ascAvaliabity al valor de su negada, para que la próxima vez tome el orden inverso.
     */
    const sortProductAvaliable = () => {
        let sortedRows
        if(ascAvaliabity){
          //Primero los disponibles
          sortedRows = [...newRows].sort((a, b) => (a.availability < b.availability ? 1 : -1))
          typeSequence = "asAvaliable"
        }else{
          //Primero los no disponibles
          sortedRows = [...newRows].sort((a, b) => (a.availability < b.availability ? -1 : 1))
          typeSequence = "desAvaliable"
        }
        setRows(sortedRows)
        ascAvaliabity = !ascAvaliabity
    }

    /**
     * Función que ordena nuestra lista de productos IProducts por el nombre de producto.
     * Si es orden ascendente, ponemos a nuestra variable typeSequence como "asProductName", para que aplique ese orden
     * Si es orden descendente tomará el valor "desProductName" para que en el switch le aplique ese orden
     * Cambia ascProductName al valor de su negada, para que la próxima vez tome el orden inverso.
     */
    const sortCategory = () => {
      let sortedRows
      if(ascCategory){
        //Sería ascendente
        sortedRows = [...newRows].sort((a, b) => (a.category < b.category ? -1 : 1))
        typeSequence = "asCategory"
      }else{
        //Sería descendente
        sortedRows = [...newRows].sort((a, b) => (a.category < b.category ? 1 : -1))
        typeSequence = "desCategory"
      }
      setRows(sortedRows)
      //En la siguiente interacción conseguimos el orden inverso
      ascCategory = !ascCategory
    }

    /**
     * Función que nos pinta una fila con los datos de un determinado producto IProduct, sin permitir edición del producto
     * @param row contiene un determinado producto, que es el que pintamos en ese momento. 
     * @returns TableRow que se va a pintar
     */
     const drawRow = (row: IProduct) =>{
      return <TableRow key={row.productName}>
              <TableCell style={{ width: 160 }} align="left">
                {<Checkbox checked={row.availability} disabled/>}
              </TableCell>
              <TableCell style={{ width: 160 }} align="left">
                {row.productName}
              </TableCell>
              <TableCell style={{ width: 160 }} align="left">
                {row.category}
              </TableCell>
              <TableCell style={{ width: 160 }} align="left">
                <DescriptionModal editFormData={row}/>
              </TableCell>
              <TableCell style={{ width: 160 }} align="left">
                {row.price}
              </TableCell>
              <TableCell style={{ width: "10%" }} align="left">
                {measureReal[row.measure]}
              </TableCell>
              <TableCell style={{ width: 160 }} align="left">
                  <ProductPictures productImage={row.image} widthImage={"130px"} heightImage={"120px"} />
              </TableCell>
              <TableCell style={{ width: "5%" }} align="left">
                  <Button onClick={event => handleEditClick(event, row)}>
                    <EditIcon fontSize="small"/>  
                  </Button>
              </TableCell>
          </TableRow>
    }

    /**
     * Función que nos pinta una fila de nuestra tabla de filtros. Así, para cada tipo de tienda pintará filtros de diferentes tipos. 
     * @param name nombre del filtro 
     * @returns FormControlLabel que se va a pintar
     */
    const drawCategoriesFilter = (name: string) => {
      return <FormControlLabel
              id={name}
              control={<Checkbox onChange={e => applyFiler(e.target.checked , name)} checked={checkStateCategory(name)}/>}
              label={name}
            />
    }

    /**
     * Función que permite recuperar el estado de las familias de productos marcados si ocultamos el menú de filtro por categorias
     * @param findCategory nombre de la categoría que queremos saber si esta seleccionada o no
     * @returns boolean indicando si se cumple la condición o no de estar previamente seleccinada esa categoria
     */
    const checkStateCategory = (findCategory: string) => {
      //si ese nombre esta en el estado de categorias guardadas, debe estar marcado como true
      return selectCategory.some(name => name === findCategory)
    }

    /**
     * Función que guarda las categorias que estan seleccionadas. 
     * @param checked booleana que nos indica si el checkbox esta marcado o no
     * @param name string que tiene el nombre de la categoría
     */
    const applyFiler = (checked: boolean, name: string) => {
      //Si esta marcada, añadimos la categoría como seleccionada para buscar en esos productos 
      if(checked){
        selectCategory?.push(name)
        setSelectCategory(selectCategory)
      }else{
        //Si no esta marcada, borramos la categoría de la lista
        const index = selectCategory.findIndex(findName => findName === name)
        selectCategory.splice(index, 1)
        setSelectCategory(selectCategory)
      }
      showFilterProduct(valueRadioButton)
    }

    /**
     * Método que sirve para actualizar la lista de productos que vamos a mostrar cuando filtremos por categoría de disponiblidad
     * En el caso particular que no haya categorias seleccionadas, se mostraran todos los productos
     */
     const showFilterProduct = (name: string) => {
      let productList: IProduct[] = []
      
      rows.forEach(currentRow => {
        if(selectCategory.length === 0){
          if(filterProductsAvaliable(currentRow, name)){
            productList.push(currentRow)
          }
        }else{
          selectCategory.forEach(currentCategory => {
            if(currentRow.category === currentCategory && filterProductsAvaliable(currentRow, name)){
              productList.push(currentRow)
            }
          })
        }
      })
      
      setRows(productList)
    }

    /**
     * Función que nos filtra en función de la disponibilidad. Por defecto, devolverá true porque estaremos buscando en todos los productos
     * @param product es el IProducto que queremos filtrar en función de si esta disponible o no
     * @returns booleana indicando si el producto esta disponible o no, y dependiendo del estado del radio button nos interesa saber si es false o true  
     */
    const filterProductsAvaliable = (product: IProduct , name: string) => {
      switch (name) {
        case "allProducts":
            return true
          break
        case "avaliableTrue":
            return (product.availability)
          break
        case "avaliableFalse":
            return (!product.availability)
          break
      }
    }

    /**
     * Método que sirve para actualizar la lista de productos que vamos a mostrar cuando filtremos por categoría
     * En el caso particular que no haya categorias seleccionadas, se mostraran todos los productos
     */
    const handleChangeRadioButtonAvaliability = (event: React.ChangeEvent<HTMLInputElement>) => {
      setValueRadioButton((event.target as HTMLInputElement).value)
    }

    /**
     * Funcion para editar los datos del formulario, actualizamos los datos con el producto que estamos modificando
     * @param event 
     * @param row es la fila que vamos a editar
     */
    const handleEditClick = (event: { preventDefault: () => void }, row: IProduct) => {
      event.preventDefault()
      setEditProduct(row.productName)

      const formValues: IProduct = {
        availability: row.availability ,
        productName: row.productName,
        category : row.category,
        price: row.price,
        measure: row.measure,
        image: row.image,
        description: row.description
      }

      setEditFormData(formValues)
    }

    /**
     * Funcion para cargar los datos al editar, obtendremos el valor del producto que hemos modificado
     * @param event 
     */
    const handleEditFormChange = (event: React.ChangeEvent<HTMLInputElement>, row: IProduct) => {
      event.preventDefault()

      /*const newFormData: IProduct = {
        availability: editFormData.availability,
        productName: editFormData.productName,
        category : editFormData.category,
        price: editFormData.price,
        measure: editFormData.measure,
        image: editFormData.image,
        description: editFormData.description
      }*/

      const newFormData: IProduct = {...editFormData}

      switch (event.currentTarget.name){
        case "image":
          if(event.currentTarget.files![0]){
            //Comprobamos que la extensión del archivo sea válida
            const extension = "." + event.currentTarget.files![0].type.split("/")[1]
            if(formatPicture.some( name => extension === name)){
              //Cambiamos @ por _ para que no de error
              let email = localStorage.getItem(keyEmail)?.replace("@","_")

              const name: string = editFormData.productName + email + extension
              newFormData.image = name

              newFormData.file = event.currentTarget.files![0]

            }else{
              //TODOO mostrar extension invalida
            }
          }
        break
      }

      switch (event.target.name) {
        case "price":
          newFormData.price = parseFloat(event.target.value)
          break;

        case "availability":
          newFormData.availability = event.target.checked
          break;
        
        case "measure":
          newFormData.measure = event.target.value
          break;
        
        case "description":
          newFormData.description = event.target.value
      }

      setEditFormData(newFormData)
    }


    /**
     * Se activa al pulsar el boton de guardar cambio en la edición del precio de un producto.
     * @param event 
     */
    const handleEditFormSubmit = (event: { preventDefault: () => void }) => {
      event.preventDefault()
      const editedRow: IProduct = {...editFormData}
      
      //Boolean que nos indica si el producto ha sido editado, o finalmente no ha variado, en cuyo caso no queremos que se guarde como nueva oferta
      let edited = true

      rows.forEach( row => {
        if(row.productName === editedRow.productName){
          //hemos encontrado el objeto que estamos editando
          if(row.price === editedRow.price && row.availability === editedRow.availability && row.measure === editedRow.measure 
            && row.image === editedRow.image && row.description === editedRow.description && row.file === editedRow.file){
            //Si se cumple esta condición, significa que NO se ha modificado el producto, por lo que no tendría sentido guardar una nueva oferta 
            edited = false
          }else{
            row.price = editedRow.price
            row.availability = editedRow.availability
            row.measure = editedRow.measure
            row.image = editedRow.image
            row.description = editedRow.description
          }
        }
      })

      //Si se ha editado, modificamos la lista de productos y llamamos a la función de actualización
      if(edited){
        newRows.forEach( row => {
          if(row.productName === editedRow.productName){
            //hemos encontrado el objeto que estamos editando
            row.price = editedRow.price
            row.availability = editedRow.availability
            row.measure = editedRow.measure
            row.image = editedRow.image
            row.description = editedRow.description
          }
        })
        
        updateProduct(editedRow)
      }else{
        setEditProduct("")
      }
      
    }

    /**
     * Función que nos permite actualizar en la base de datos un producto, se activa al pulsar el botón de guardado
     * @param product producto que queremos actualizar
     */
    const updateProduct = (product: IProduct) => {

      if(product.file){
        const formData = new FormData()

        formData.append("file", product.file!)
        formData.append("fileName", product.image)
    
        console.log(formData)
        const config = {     
          headers: { 'content-type': 'multipart/form-data' }
        }
        //TODOO mejorar

        axios.post(URL_UPLOAD_PICTURES, formData, config)
        .then(response => {
            console.log("Respuesta de subida de imagen: " + response)
            apiPost(URL_UPDATE_PRODUCTS, product)
              .then(data => {
                console.log(data)
              }).catch(error => console.log(error)) 
        })
        .catch(error => {
            console.log("Error subiendo imagen: " + error)
        })

      }else{
        console.log("NO HEMOS MODIFICADO EL FICHERO DE IMAGEN")
        apiPost(URL_UPDATE_PRODUCTS, product)
        .then(data => {
          console.log(data)
        }).catch(error => console.log(error)) 
      }

      setEditProduct("")
      setRows(newRows)
    }

    /**
     * Función para cancelar la edición de un producto
     */
    const cancelEdition = () => {
      //Ponemos este estado a vacío para cancelar el modo edición de un producto
      setEditProduct("")
    }

    /**
     * Función para visualizar las categorías de filtros que queremos desplegar
     * @param typeFilter 
     */
    const viewFiler = (typeFilter: string) => {
      switch (typeFilter) {
        case "categories":
          setViewCategoryFilter(!viewCategoryFilter)
          break;
        case "avaliability":
          setViewAvaliabilityFilter(!viewAvaliabilityFilter)
          break;
      }
    }

    return(
        <div>
            <div style={{display: 'flex',  justifyContent:'center', alignItems:'center'}}>
                <h1> Productos</h1>
            </div>
            <br/>
            <Grid container direction="row" justifyContent="flex-end"> 
              <input
                type="text"
                placeholder="Buscar por nombre"
                className="textField"
                name="busqueda"
                value={search}
                onChange={onChangeSearchField}
              />
              <Button type="button" className="btnBuscar">
                {" "}
                <SearchIcon />
              </Button>
            </Grid>
            <br />
            <Grid direction="row" alignItems="flex-start" alignContent="center" container>
                <Grid item xs={2} sm={2}>
                  <Table className="filterCategories" size="small" aria-label="a dense table" >
                      <TableHead>
                          <TableRow key="headCategory">
                              <TableCell key="filterLabel" align="left">Filtros por categoría</TableCell>
                              <Button onClick={e => viewFiler('categories')}>
                                <ArrowDropDownRoundedIcon fontSize="large"/>
                              </Button>
                          </TableRow>
                      </TableHead>
                      <TableBody>
                        {viewCategoryFilter ? (<FormGroup>
                          {category?.map((name) => (
                            drawCategoriesFilter(name)
                          ))}
                        </FormGroup>) : <TableCell/>}
                      </TableBody>
                      <TableHead>
                        <TableRow key="headAvaliable">
                            <TableCell key="filterLabel" align="left">Filtros por disponibilidad</TableCell>
                            <Button onClick={e => viewFiler('avaliability')}>
                              <ArrowDropDownRoundedIcon fontSize="large"/>
                            </Button>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {viewAvaliabilityFilter ? (
                          <RadioGroup aria-label="Filtros por disponibilidad" name="avaliableProduct" value={valueRadioButton} onChange={handleChangeRadioButtonAvaliability} >
                          <FormControlLabel value="allProducts" control={<Radio onChange={e => showFilterProduct(e.target.value)}/>} label="Todos los productos" />
                          <FormControlLabel value="avaliableTrue" control={<Radio onChange={e => showFilterProduct(e.target.value)}/>} label="Disponible" />
                          <FormControlLabel value="avaliableFalse" control={<Radio onChange={e => showFilterProduct(e.target.value)}/>} label="No disponible"/>
                        </RadioGroup>
                        ) : (<TableCell/>)}
                      </TableBody>
                  </Table>
                </Grid>
                
                <Grid item xs={10} sm={10}>    
                  <Table className={classes.table} aria-label="custom pagination table">
                    <TableHead>
                      <TableRow key="cabeceras">
                        <TableCell style={{ width: "10%" }} key="availability" align="left">Disponibilidad 
                            <Button onClick={sortProductAvaliable}>
                              {ascAvaliabity ? (<ArrowDropDownRoundedIcon/>) : (<ArrowDropUpRoundedIcon/>)}
                            </Button>
                        </TableCell>
                        <TableCell key="productName" align="left">Nombre
                            <Button onClick={sortProductName}>
                              {ascProductName ? (<ArrowDropUpRoundedIcon/>) : (<ArrowDropDownRoundedIcon/>)}
                            </Button>
                        </TableCell>
                        <TableCell key="category" align="left">Categoría
                            <Button onClick={sortCategory}>
                              {ascCategory ? (<ArrowDropUpRoundedIcon/>) : (<ArrowDropDownRoundedIcon/>)}
                            </Button>
                        </TableCell>
                        <TableCell key="description" align="left">Descripción</TableCell>
                        <TableCell key="price" align="left">Precio (€)
                            <Button onClick={sortProductPrice}>
                              {ascPrice ? (<ArrowDropDownRoundedIcon/>) : (<ArrowDropUpRoundedIcon/>)}
                            </Button>
                        </TableCell>
                        <TableCell style={{ width: "10%" }} key="measure" align="left">Medida </TableCell>
                        <TableCell key="productPicture" align="left">Imagen</TableCell>
                        <TableCell key="editProduct" align="left">Editar</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                        {filterAndPaginate().map((row) => (
                          editProduct === row.productName ? (<EditableRow editFormData={editFormData} handleEditFormChange={handleEditFormChange} handleEditFormSubmit={handleEditFormSubmit} cancelEdition={cancelEdition} />) 
                          : (drawRow(row))
                        ))}
                        {emptyRows > 0 && (
                            <TableRow style={{ height: 53 * emptyRows }}>
                              <TableCell colSpan={6} />
                            </TableRow>
                        )}
                    </TableBody>
                    <TableFooter>
                      <TableRow>
                        <TablePagination
                            rowsPerPageOptions={[5, 10, 25, { label: 'Todos', value: -1 }]}
                            colSpan={7}
                            labelRowsPerPage="Filas por página"
                            count={newRows.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            SelectProps={{
                                inputProps: { 'aria-label': 'rows per page' },
                                native: true,
                            }}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            ActionsComponent={TablePaginationActions}
                        />
                      </TableRow>
                    </TableFooter>
                  </Table>   
                </Grid> 
            </Grid>
        </div> 
    )
}

export default Products