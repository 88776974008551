import React from 'react'
import { Link } from 'react-router-dom'
import { Button } from '@material-ui/core'

/* Si estas identificado que salga profile, manager y configuracion
*/ 

const MainPage = (props: any) => {
    return(
        <div>
            <div style={{display: 'flex',  justifyContent:'center', alignItems:'center', marginTop: "2em"}}>
                <h1> Bienvenido al registro Cobato</h1>
            </div>
            <div style={{display: 'flex',  justifyContent:'center', alignItems:'center'}}>
                <Button color="primary" component={Link} to="/business" variant="contained">
                    Ir a la creación de un negocio
                </Button>
                <br/>
            </div>
        </div>
    )
}

export default MainPage