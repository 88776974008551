import { keyToken } from './constants'
import jwt from 'jsonwebtoken'

enum HttpMethod{
	GET = "GET",
	POST = "POST",
	PUT = "PUT",
	DELETE = "DELETE"
}

const api = async (url : string, method : HttpMethod, body? : any) => {
	const token = localStorage.getItem(keyToken)

    const requestHeaders: HeadersInit = new Headers();
    if(token){
		const tokenDecoded: any = jwt.decode(token);
		const tokenExpirationDate = tokenDecoded?.exp * 1000
		const tokenDateExpiration = new Date(tokenExpirationDate)
		//Creo fecha actual para comprobarlo
		var currentDate = new Date()
		if(currentDate > tokenDateExpiration){
			localStorage.removeItem(keyToken)
			
		}else{
			requestHeaders.set(keyToken, token);
		}
        
    }
        
    requestHeaders.set('Content-Type', 'application/json');

	const payload = {
		method,
		headers: requestHeaders,
		body: JSON.stringify(body)
	}
	const response = await fetch(url, payload)

	const jsonResponse = await response.json()
	if(response.status === 400){
		throw new Error(jsonResponse)
	}

	if(jsonResponse.error)
		throw new Error(jsonResponse.error)
	return jsonResponse
}

export const apiGet = async (url: string) => await api(url, HttpMethod.GET)
export const apiPost = async (url: string, body: any) => await api(url, HttpMethod.POST, body)
