import React, { useCallback, useReducer, useContext } from 'react'
import MuiAlert, { Color } from '@material-ui/lab/Alert'
import { Snackbar } from '@material-ui/core'
import { SnackbarMessage } from '../types/SnackbarMessage'

import snackbarReducer from '../reducers/Snackbar'
import { close } from '../actions/Snackbar';



const initialValue : SnackbarMessage = {
    message: '',
    severity: "info"
}

const SnackbarStateContext = React.createContext<SnackbarMessage>(initialValue)
const SnackbarDispatchContext = React.createContext<React.Dispatch<any>|any>({})

type Prop = {
    children: React.ReactNode
}

const SnackbarContext : React.FC<Prop> = ({children}) =>{
    const reducer = useCallback(snackbarReducer ,[])
    const [state, dispatch] = useReducer(reducer, initialValue)

    const {message, severity} = state

    const handleCloseSnackbarAlert = (event : any) => {
        dispatch(close())
    };
    
    return (
        <SnackbarDispatchContext.Provider value={dispatch}>
            <SnackbarStateContext.Provider value={state}>
                <Snackbar open={!!message} autoHideDuration={6000}>
                    <MuiAlert elevation={6} variant="filled" severity={severity} onClose={handleCloseSnackbarAlert}>
                        {message}
                    </MuiAlert>
                </Snackbar>
                {children}
            </SnackbarStateContext.Provider>
        </SnackbarDispatchContext.Provider>
    )
}

const useSnackbarDispatchContext = () => {
    const dispatch = useContext(SnackbarDispatchContext)
    if(!dispatch)
        throw Error("Must set dispatch provider")
    return dispatch
}

const useSnackbarStateContext = () => {
    const state = useContext<SnackbarMessage>(SnackbarStateContext)
    if(!state)
        throw Error("Must set dispatch provider")
    return state
}
export default SnackbarContext

export {
    useSnackbarDispatchContext,
    useSnackbarStateContext
}
