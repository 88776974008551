/**
 * Componente creado para gestionar el horario estandar del tendero
 */

//TODO SACAR UNA CLASE PARA CALENDARIO ESTANDAR
import React, { useEffect, useState } from 'react'
import { apiPost, apiGet } from '../../utils/api'

import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import Button from '@material-ui/core/Button'
import { useSnackbarDispatchContext } from '../../contexts/SnackbarContext'
import { add } from '../../actions/Snackbar'
import { URL_GET_CALENDAR_STANDARD, URL_DELETE_CALENDAR_STANDARD, URL_INSERT_MANAGER_CALENDAR_STANDARD } from '../../utils/constants'

//PROBANDO
type SchedulerDayRange = {
    from: number,
    to: number
}

type Prop = {
    range?: SchedulerDayRange
}

//PROBANDO
const isSelected = ( from : number, to : number, range?:SchedulerDayRange) => {
    if(!range) return false
    return (range.from >= from && range.from <= to) || (range.to >= to && range.from <= to)
}

const hours = ["7:00" , "7:30", "8:00", "8:30", "9:00", "9:30", "10:00", "10:30", "11:00" , "11:30", "12:00", "12:30", "13:00", "13:30", "14:00", "14:30",
               "15:00" , "15:30", "16:00", "16:30", "17:00", "17:30", "18:00", "18:30", "19:00" , "19:30", "20:00", "20:30", "21:00", "21:30", "22:00",
               "22:30", "23:00", "23:30", "00:00", "00:30", "1:00", "1:30", "2:00" , "2:30", "3:00", "3:30", "4:00", "4:30", "5:00",
               "5:30", "6:00", "6:30"]

const days = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"]
var rangeHours: string[] = []

interface IManagerCalendar {
	gr__opens: number;
	gr__closes: number;
	gr__day_of_week : string;
    gr__validFrom: string;
	gr_validThrough: string;
}

//Creamos una matriz que relacione días con horas
const StandardCalendar : React.FC<Prop> = ({range}) => {
    const [ selectedHours, setSelectedHours ] = useState<boolean[][]>(
        Array.from({length: days.length}, () => Array.from({length: hours.length}, () => false))
    ) 

    const [dayRange, setDayRange] = useState<SchedulerDayRange|undefined>(range)
    const [selecting, setSelecting] = useState(false)
    const snackbarDispatch = useSnackbarDispatchContext()

    const handleClick = (from : number) => ( e : MouseEvent ) => {
        if(selecting){
            setDayRange( (oldDayRange) => (oldDayRange && {...oldDayRange, to: from+100}))
        }else{
            setDayRange( (oldDayRange) => ({...oldDayRange, from, to: from+100}))
        }        
        setSelecting((oldSelecting) => !oldSelecting) 
    }
    //FIN PROBANDO

    const getHourCorrect = (data: number) => {
        //Obtengo el indice de las horas
        let hourRow: number = (data / 1440) * (hours.length + 2)
        if (data >= 0 && data <= 390){
            //rango de 12 a 6:30
            hourRow += 48
        }
        hourRow += hourRow <= 14 ? 0 : -14
        if(hourRow < 0){
            hourRow = hours.length - hourRow 
        }
        hourRow += hourRow <= 14 ? 0 : -1
        return Math.floor(hourRow)
    } 


    //Metodo para pintar los horarios cargados en la base de datos
    const updateCalendar = (data: IManagerCalendar[]) => {
        
        for(var i = 0; i < data.length; i++){
            let currentDay = data[i].gr__day_of_week
            let openHour: number = getHourCorrect(data[i].gr__opens) 
            let closeHour: number = getHourCorrect(data[i].gr__closes) 
            for(var j = 0; j < days.length; j++){
                if (days[j] === currentDay){
                    toggleCell(j, openHour)
                    //Si son distintos necesitamos pintar mas de una casilla por dia
                    if(openHour !== closeHour-1){
                        var cont = openHour+1
                        while(cont <= closeHour-1){
                            toggleCell(j, cont)
                            cont++
                        } 
                    } 
                }
            }
        }
    }
    
    //Método para cargar el horario estandar del usuario
    useEffect(() => {
        apiGet(URL_GET_CALENDAR_STANDARD)                  
        //apiGet('http://s3-ceatic.ujaen.es:8013/api/managerCalendar/getBankHoliday')
        .then(data => {
            console.log(data)
            updateCalendar(data)
        })
        .catch(error => console.log(error))
        
    }, [])
    
    const toggleCell = (i: number, j: number) => {
        let copy = [...selectedHours]
        //Ponemos a true los seleccionados
        copy[i][j] = !copy[i][j]
        setSelectedHours(copy)
    }
    const renderTable = () => hours.map(drawRow)    

    const refreshCalendar = () => {
        let copyRefresh = Array.from({length: hours.length}, () => Array.from({length: days.length}, () => false))
        setSelectedHours(copyRefresh)
    }

    const deleteCalendar = () => {
        //TODO modificar por apiDelete
        apiPost(URL_DELETE_CALENDAR_STANDARD, rangeHours)                  
        //apiPost('http://s3-ceatic.ujaen.es:8013/api/managerCalendar/deleteCalendarStandard', rangeHours)
        .then(data => {
            //console.log(data)
            refreshCalendar()
        })
        .catch(error => console.log(error))
    }

    //Funcion para devolver los horarios que queremos insertar en nuestra base
    const conversionHours = () =>{
        var startTime = ''
        var endTime = ''
        var i , j
        var cont = 0
        for(i=0; i < days.length; i++){
            for(j=0; j < hours.length; j++){
                if(selectedHours[i][j] && startTime ===''){
                    startTime = `${hours[j]}:${days[i]}`
                }
                //Estamos en que es el primer false, ya no se incluye
                if(!selectedHours[i][j] && endTime ==='' && startTime !==''){
                    endTime = `${hours[j]}:${days[i]}`
                }
                //Ambos estan asignados y tengo un rango horario
                if(endTime !=='' && startTime !==''){
                    rangeHours[cont] = `${startTime}:${endTime}`
                    cont++
                    startTime = ''
                    endTime = ''
                }else if (j === hours.length-1 && startTime !=='' && endTime ===''){
                    //Si llegamos al final del dia 
                    endTime = `${hours[0]}:${days[i]}`
                    rangeHours[cont] = `${startTime}:${endTime}`
                    cont++
                    startTime = ''
                    endTime = ''
                }
            }
        }
        
        console.log(rangeHours.length)
        onSubmit(rangeHours)
        
    }

    const onSubmit = (rangeHours: string[]) =>{
        console.log(rangeHours)
        apiPost(URL_INSERT_MANAGER_CALENDAR_STANDARD, rangeHours)                  
        //apiPost('http://s3-ceatic.ujaen.es:8013/api/managerCalendar/insertManagerCalendarStandar', rangeHours)
        .then(data => {
            console.log(data)
            // TODO poner cuando haces peticiones en los botones para mostrar el mensaje en pantalla
            snackbarDispatch(add({ message: data, severity: "success"}))
        })
        .catch(error => snackbarDispatch(add({ message: error, severity: "error"})))
    }
    
    const drawRow = (hour: string, hourIndex : number) =>{
        return <TableRow key={`${hour}-${hourIndex}`}>
            <TableCell key="hours" align="center">{hour}</TableCell>
            { days.map((day, index) => drawCell(index, hourIndex))}
        </TableRow>
    }
    const drawCell = (i: number, j: number) => (
        <TableCell key={`${i}-${j}`} className="clickCell" align="center" onClick={() => toggleCell(i,j)}>{selectedHours[i][j] ? "X" : ""}</TableCell>
    )

    return (
        <div>
            <div>
                <div style={{display: 'flex',  justifyContent:'center', alignItems:'center'}}>
                    <h1> Horario estándar</h1>
                </div>
                <br/>
                    <TableContainer component={Paper}>
                        <Table className="standardCalendar" size="small" aria-label="a dense table" >
                            <TableHead>
                                <TableRow key="cabeceras">
                                    <TableCell key="horas" align="center">Horas</TableCell>
                                    <TableCell key="Lunes" align="center">Lunes</TableCell>
                                    <TableCell key="Martes" align="center">Martes</TableCell>
                                    <TableCell key="Miércoles" align="center">Miércoles</TableCell>
                                    <TableCell key="Jueves" align="center">Jueves</TableCell>
                                    <TableCell key="Viernes" align="center">Viernes</TableCell>
                                    <TableCell key="Sábado" align="center">Sábado</TableCell>
                                    <TableCell key="Domingo" align="center">Domingo</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {renderTable()}
                            </TableBody>
                        </Table>
                    </TableContainer>
            </div>
            <div>
                <br/>
                <Button variant="contained" color="primary" onClick={conversionHours}>
                    Guardar
                </Button>
                &nbsp;
                <Button variant="contained" color="primary" onClick={deleteCalendar}>
                    Borrar horario
                </Button>
            </div>
            
        </div>
    )
}


export default StandardCalendar