import React, { useEffect, useState } from 'react'
import { apiGet, apiPost } from '../utils/api'
import { ProfileDataPostal } from '../types/Profile'
import { URL_PROFILE } from '../utils/constants'

const dataProfile: ProfileDataPostal = ({
    streetAddress: "",
    postalCode: "",
    locality : "",
    country: "",
    email: "",
    pre_telephone: "",
    telephone: "",
    hash_pwd: "",
    language: "",
    link: "",
    qr: "",
})

export const useProfile = () => {
    const [profile, setProfile] = useState<ProfileDataPostal>(dataProfile)
    useEffect( () => {
        apiGet(URL_PROFILE).then((data) => {
                setProfile(data);
            }).catch(error => {
                throw Error(error)
            })
    },[])
    return profile
}


