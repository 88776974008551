export const keyToken = "auth-token"
export const keyEmail = "keyEmail"

//TODO Constantes de URL 
export const URL_SIGNIN = "http://localhost:8013/api/auth/signin" // source/react/src/utils/session.ts
export const URL_VALIDATE_TOKEN = "http://localhost:8013/api/auth/validateToken" // source/react/src/utils/PrivateRoute.tsx
export const URL_PROFILE = "http://localhost:8013/api/auth/profile" // source/react/src/api/auth.ts
export const URL_INSERT_BANK_HOLIDAY = "http://localhost:8013/api/managerCalendar/insertBankHoliday" // source/react/src/components/BankHoliday/BankHoliday.tsx
export const URL_DELETE_BANK_HOLIDAY = "http://localhost:8013/api/managerCalendar/deleteBankHoliday" // source/react/src/components/BankHoliday/BankHoliday.tsx
export const URL_GET_BANK_HOLIDAY = "http://localhost:8013/api/managerCalendar/getBankHoliday" // source/react/src/components/BankHoliday/BankHoliday.tsx
export const URL_INSERT_BUSINESS = "http://localhost:8013/api/business/insertBusiness" // source/react/src/components/BusinessCreate/BusinessCreate.tsx
export const URL_INSERT_POSTAL_ADDRESS = "http://localhost:8013/api/postalAddress/insertPostalAddress" // source/react/src/components/PostalAddressCreate/PostalAddressCreate.tsx
export const URL_UPDATE_PROFILE = "http://localhost:8013/api/auth/updateProfile" // source/react/src/components/Profile/Profile.tsx
export const URL_GET_CALENDAR_STANDARD = "http://localhost:8013/api/managerCalendar/getCalendarStandard" // source/react/src/components/StandardCalendar/StandardCalendar.tsx
export const URL_DELETE_CALENDAR_STANDARD = "http://localhost:8013/api/managerCalendar/deleteCalendarStandard" // source/react/src/components/StandardCalendar/StandardCalendar.tsx
export const URL_INSERT_MANAGER_CALENDAR_STANDARD = "http://localhost:8013/api/managerCalendar/insertManagerCalendarStandar" // source/react/src/components/StandardCalendar/StandardCalendar.tsx

export const URL_GET_PRODUCT = "http://localhost:8013/api/products/getProducts" // source/react/src/components/Products/Products.tsx
export const URL_GET_CATEGORIES = "http://localhost:8013/api/products/getCategories" // source/react/src/components/Products/Products.tsx
export const URL_UPLOAD_PICTURES = "http://localhost:8013/api/pictures/upload" // source/react/src/components/Products/Products.tsx
export const URL_UPDATE_PRODUCTS = "http://localhost:8013/api/products/updateProducts" // source/react/src/components/Products/Products.tsx

export const URL_PRODUCT_PICTURE = "http://localhost:8013/ProductPicture/" // source/react/src/components/ProductPictures/ProductPictures.tsx
export const URL_QR = "http://localhost:8013/QRs/" // source/react/src/components/Profile/Profile.tsx

//TODO Constantes de URL para cuando esten en el servidor de la uja
/*export const URL_SIGNIN = "http://s3-ceatic.ujaen.es:8013/api/auth/signin" // source/react/src/utils/session.ts
export const URL_VALIDATE_TOKEN = "http://s3-ceatic.ujaen.es:8013/api/auth/validateToken" // source/react/src/utils/PrivateRoute.tsx
export const URL_PROFILE = "http://s3-ceatic.ujaen.es:8013/api/auth/profile" // source/react/src/api/auth.ts
export const URL_INSERT_BANK_HOLIDAY = "http://s3-ceatic.ujaen.es:8013/api/managerCalendar/insertBankHoliday" // source/react/src/components/BankHoliday/BankHoliday.tsx
export const URL_DELETE_BANK_HOLIDAY = "http://s3-ceatic.ujaen.es:8013/api/managerCalendar/deleteBankHoliday" // source/react/src/components/BankHoliday/BankHoliday.tsx
export const URL_GET_BANK_HOLIDAY = "http://s3-ceatic.ujaen.es:8013/api/managerCalendar/getBankHoliday" // source/react/src/components/BankHoliday/BankHoliday.tsx
export const URL_INSERT_BUSINESS = "http://s3-ceatic.ujaen.es:8013/api/business/insertBusiness" // source/react/src/components/BusinessCreate/BusinessCreate.tsx
export const URL_INSERT_POSTAL_ADDRESS = "http://s3-ceatic.ujaen.es:8013/api/postalAddress/insertPostalAddress" // source/react/src/components/PostalAddressCreate/PostalAddressCreate.tsx
export const URL_UPDATE_PROFILE = "http://s3-ceatic.ujaen.es:8013/api/auth/updateProfile" // source/react/src/components/Profile/Profile.tsx
export const URL_GET_CALENDAR_STANDARD = "http://s3-ceatic.ujaen.es:8013/api/managerCalendar/getCalendarStandard" // source/react/src/components/StandardCalendar/StandardCalendar.tsx
export const URL_DELETE_CALENDAR_STANDARD = "http://s3-ceatic.ujaen.es:8013/api/managerCalendar/deleteCalendarStandard" // source/react/src/components/StandardCalendar/StandardCalendar.tsx
export const URL_INSERT_MANAGER_CALENDAR_STANDARD = "http://s3-ceatic.ujaen.es:8013/api/managerCalendar/insertManagerCalendarStandar" // source/react/src/components/StandardCalendar/StandardCalendar.tsx

export const URL_GET_PRODUCT = "http://s3-ceatic.ujaen.es:8013/api/products/getProducts" // source/react/src/components/Products/Products.tsx
export const URL_GET_CATEGORIES = "http://s3-ceatic.ujaen.es:8013/api/products/getCategories" // source/react/src/components/Products/Products.tsx
export const URL_UPLOAD_PICTURES = "http://s3-ceatic.ujaen.es:8013/api/pictures/upload" // source/react/src/components/Products/Products.tsx
export const URL_UPDATE_PRODUCTS = "http://s3-ceatic.ujaen.es:8013/api/products/updateProducts" // source/react/src/components/Products/Products.tsx

export const URL_PRODUCT_PICTURE = "http://s3-ceatic.ujaen.es:8013/ProductPicture/" // source/react/src/components/ProductPictures/ProductPictures.tsx
export const URL_QR = "http://localhost:8013/QRs/" // source/react/src/components/Profile/Profile.tsx*/
