import React from 'react';
import Products from '../components/Products';


const ProductsPage = (props: any) => {
    return(
        <div>
            <Products/>
        </div>
    )
}


export default ProductsPage