//REVISAR TODO
import React, { useEffect, useState } from 'react'
import { Route, Redirect } from 'react-router-dom'
import { keyToken } from './constants'
import { apiGet } from '../utils/api'
import { URL_VALIDATE_TOKEN } from '../utils/constants'

const PrivateRoute = ({ children, ...rest}: any) => {
    const [auth, setAuth] = useState<boolean>(false)
    const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false)
    useEffect( () => {
        const token = localStorage.getItem(keyToken)
        if (token) {
            //Petición para ver si el token sigue actualizado
            apiGet(URL_VALIDATE_TOKEN)
            .then(() => {
                setAuth(true)
            })
            .catch(error => {
                setAuth(false)
                localStorage.removeItem(keyToken)
            })
            .then(() => setIsAuthenticated(true))
            
        } else {
            setIsAuthenticated(true)
        }
    
    },[])
    
    if (!isAuthenticated) return <div />
    
    return (<Route {...rest}
        render={(props) => {
          return auth ? children : <Redirect to={{pathname: '/signin'}} />
        }} />)
      
}
export default PrivateRoute
