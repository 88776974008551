import React from 'react'
import StandardCalendar from '../components/StandardCalendar'
import BankHoliday from '../components/BankHoliday'
import { Paper, Tabs, Tab } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';


const useStyles = makeStyles({
    root: {
      flexGrow: 1,
    },
  });

interface TabPanelProps {
children?: React.ReactNode;
index: any;
value: any;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography component={'span'}>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

const ManagerCalendarPage = (props: any) => {
    const classes = useStyles();
    const [value, setValue] = React.useState(0);

    const handleChange = (event: any, newValue: React.SetStateAction<number>) => {
        setValue(newValue);
    };
    return(
        <div>
            <Paper className={classes.root}>
                <Tabs
                    value={value}
                    onChange={handleChange}
                    indicatorColor="primary"
                    textColor="primary"
                    centered
                >
                    <Tab label="Jornada laboral" />
                    <Tab label="Días no laborables"/>
                    
                </Tabs>
            </Paper>
            <TabPanel value={value} index={0}>
                <StandardCalendar/>
            </TabPanel>
            <TabPanel value={value} index={1}>
                <BankHoliday/>
            </TabPanel>
        </div>
    )
}


export default ManagerCalendarPage