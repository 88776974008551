import React from 'react';
import Profile from '../components/Profile';


const ProfilePage = (props: any) => {
    return(
        <div>
            <Profile/>
        </div>
    )
}


export default ProfilePage