import { Session } from "../types/Session";
import { SessionsActions } from "../actions/Session";


const baseReducer : any = {
    [SessionsActions.AUTHENTICATE]: (state : Session, action : any) => {
        const authToken = action.payload;
        return {...state, authToken}
    },
    [SessionsActions.LOGOUT]: (state : Session, action : any) => {
        return {...state, authToken: null, user: null};
    }
}

const reducer = (state : Session, action : any) => baseReducer[action.type] && baseReducer[action.type](state, action)
export default reducer