import React from 'react'
import { styled } from '@mui/material/styles'
import Button from '@mui/material/Button'
import CloseIcon from '@mui/icons-material/Close'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'

interface IProduct {
    availability: boolean;
    productName: string;
    category : string;
    price: number;
    measure: string;
    image: string;
    description: string;
} 

type Prop = {
    editFormData: IProduct
}

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuDialogActions-root': {
        padding: theme.spacing(1),
    },
}))

export interface DialogTitleProps {
    id: string;
    children?: React.ReactNode;
    onClose: () => void;
}

const BootstrapDialogTitle = (props: DialogTitleProps) => {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
        {children}
        {onClose ? (
            <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
            }}
            >
            <CloseIcon />
            </IconButton>
        ) : null}
        </DialogTitle>
    )
}

const DescriptionModal: React.FC<Prop> = ({ editFormData }) => {
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => {
        setOpen(true)
    };
    const handleClose = () => {
        setOpen(false);
    }
    return (
        <div>
            <Button onClick={handleOpen}>Descripción</Button>
            <BootstrapDialog
                fullWidth
                onClose={handleClose}
                aria-labelledby="dialog-title"
                open={open}
            >
                <BootstrapDialogTitle id="descriptionTitle" onClose={handleClose}>
                    {editFormData.productName}
                </BootstrapDialogTitle>
                <DialogContent dividers>
                    <Typography gutterBottom>
                        {editFormData.description !== "" ? (editFormData.description) : "Actualmente no hay una descripción para este producto"}
                    </Typography>
                </DialogContent>
            </BootstrapDialog>
        </div>
    )
}

export default DescriptionModal