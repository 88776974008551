import { SnackbarActions } from "../actions/Snackbar";
import { SnackbarMessage } from "../types/SnackbarMessage";


const baseReducer : any = {
    [SnackbarActions.ADD]: (state : SnackbarMessage, action : any) => {
        return {...state, ...action.payload}
    },
    [SnackbarActions.CLOSE]: (state : SnackbarMessage, action : any) => {
        return {message: '', severity: "info"};
    }
}

const reducer = (state : SnackbarMessage, action : any) => baseReducer[action.type] && baseReducer[action.type](state, action)
export default reducer