import React from 'react'
import { URL_PRODUCT_PICTURE } from '../../utils/constants'

type Prop = {
    productImage: string,
    widthImage: string,
    heightImage: string
}

//TODOO modificar para que se coja la ruta del sistema
const PATH: string = URL_PRODUCT_PICTURE
const defaultImage = "default.png"

const ProductPictures: React.FC<Prop> = ({ productImage, widthImage, heightImage }) => {
    if (productImage === undefined || productImage === ""){
        productImage = defaultImage
    }
    return (
        <img src={PATH + productImage + "?" + Math.random() } width={widthImage} height={heightImage}/>
    )
}

ProductPictures.propTypes = {
    
}

export default ProductPictures