/**
 * Componente creado para gestionar los dias festivos del tendero
 */
import React, { useEffect, useState } from 'react'
import { DatePicker } from 'react-trip-date'
import { apiPost, apiGet } from '../../utils/api'
import Button from '@material-ui/core/Button'
import { URL_INSERT_BANK_HOLIDAY, URL_DELETE_BANK_HOLIDAY, URL_GET_BANK_HOLIDAY } from '../../utils/constants'
import { useSnackbarDispatchContext } from '../../contexts/SnackbarContext'
import { add } from '../../actions/Snackbar'


type Prop = {

}

const BankHoliday : React.FC<Prop> = () => {
    const snackbarDispatch = useSnackbarDispatchContext()

    const onSubmit = () =>{
        apiPost(URL_INSERT_BANK_HOLIDAY, bankHolidayDays)                  
        //apiPost('http://s3-ceatic.ujaen.es:8013/api/managerCalendar/insertBankHoliday', bankHolidayDaysDate)
        .then(data => {
            console.log(data)
            snackbarDispatch(add({ message: data, severity: "success"}))
        })
        .catch(error => snackbarDispatch(add({ message: error, severity: "error"})))
    }

    const deleteHoliday = () =>{
        //TODO cambiar a delete cuando la implemente
        apiGet(URL_DELETE_BANK_HOLIDAY)                  
        //apiGet('http://s3-ceatic.ujaen.es:8013/api/managerCalendar/deleteBankHoliday')
        .then(data => {
            console.log(data)
            //poner los dias vacios
            const noDays: string[] = [];
            setBankHolidayDays(noDays)
        })
        .catch(error => console.log(error))
    }

    const onChange = (days : string[]) => {
        console.log(days)
        setBankHolidayDays(days)
    }
    const [bankHolidayDays, setBankHolidayDays] = useState<string[]>([]);
    useEffect(() => {
        apiGet(URL_GET_BANK_HOLIDAY)                  
        //apiGet('http://s3-ceatic.ujaen.es:8013/api/managerCalendar/getBankHoliday')
        .then(data => {
            if(data.length ){
                console.log(data)
                const correctFormatData = data.map ((d: string) => d.split('T')[0])
                setBankHolidayDays(correctFormatData)
            }
        })
        .catch(error => console.log(error))
        
    }, [])
    return (
        <div>
            <div style={{display: 'flex',  justifyContent:'center', alignItems:'center'}}>
                <h1> Dias festivos</h1>
            </div>
            <br/>
            <DatePicker
                onChange={onChange}
                selectedDays={bankHolidayDays} 
                numberOfSelectableDays={0}
            />
            <div>
                <br/>
                <Button variant="contained" color="primary" onClick={onSubmit}>
                    Guardar
                </Button>
                &nbsp;
                <Button variant="contained" color="primary" onClick={deleteHoliday}>
                    Borrar
                </Button>
                
            </div>
        </div>
    )
}

export default BankHoliday