import React from 'react'
import { Link, useHistory } from 'react-router-dom'
import { removeSession } from '../../../utils/session'
import Button from '@material-ui/core/Button'

import { createStyles, makeStyles, Theme} from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import { useSessionDispatchContext, useSessionStateContext } from '../../../contexts/SessionContext'


const drawerWith = 300
const useStyles = makeStyles( (theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1
        },
        title: {
            justifyContent: "flex-start"
        },
        menu: {
            justifyContent: "flex-end",
            display: "flex",
            flexGrow: 1
        },
        drawer: {
            width: drawerWith,
            flexShrink: 0
        },
        drawerPaper: {
            widht: drawerWith
        },
        drawerHeader: {
            display: 'flex',
            alignItems: 'center',
            padding: theme.spacing(0, 1),
            ...theme.mixins.toolbar,
            justifyContent: 'flex-end'
        },
        backdrop: {
            zIndex: theme.zIndex.drawer - 1
        }
    })
)
const Header = () => {
    const state = useSessionStateContext()
    const isAuthenticated = !!state.authToken
    const dispatch = useSessionDispatchContext()

    const history = useHistory()

    const classes = useStyles()
   
	return <div className={classes.root}>
        <AppBar position="static">
            <Toolbar>
                <Button color="inherit" className={classes.title} component={Link} to="/">
                    Cobato
                </Button>
                <Button color="inherit" className={classes.title} href="/landing.html">
                    Tutorial
                </Button>
                <div className={classes.menu}>
                    <Button color="inherit" component={Link} to="/managerCalendar">
                        Horarios
                    </Button>
                    <Button color="inherit" component={Link} to="/profile">
                        Perfil
                    </Button>
                    <Button color="inherit" component={Link} to="/products">
                        Productos
                    </Button>
                    {
                        !isAuthenticated ? 
                        <Button color="inherit" component={Link} to="/signin">
                            Login
                        </Button> :
                        <Button color="inherit" onClick={() => {
                                removeSession(dispatch)
                                history.push("/")
                            }} >
                            Logout
                        </Button> 
                    }
                </div>
                
                
            </Toolbar>
        </AppBar>
        
    </div>
}

export default Header
