import React from 'react'
import PostalAddressCreate from '../components/PostalAddressCreate'


const PostalAddressFormPage = (props: any) => {
    return(
        <div>
            <PostalAddressCreate nameBusiness={props.name}/>
        </div>
    )
}


export default PostalAddressFormPage