import { Button } from '@material-ui/core'
import React from 'react'
import { apiPost } from '../../utils/api'
import MenuItem from '@material-ui/core/MenuItem'

import { FormikHelpers, useFormik } from 'formik'
import TextField from '@material-ui/core/TextField'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import ProfileSchema from '../schema/ProfileSchema'
import { useProfile } from '../../api/auth'
import { ProfileDataPostal } from '../../types/Profile'
import { URL_UPDATE_PROFILE, URL_QR, URL_PRODUCT_PICTURE } from '../../utils/constants'
import { useSnackbarDispatchContext } from '../../contexts/SnackbarContext'
import { add } from '../../actions/Snackbar'

import Grid from '@material-ui/core/Grid'

type Prop = {
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& .MuiTextField-root': {
        margin: theme.spacing(1),
        width: '25ch',
      },
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
  }),
);

const currentCountry = [
    {
      value: 'EN',
      label: 'England',
    },
    {
      value: 'ES',
      label: 'España',
    },
];

const currentLanguage = [
    {
      value: 'en',
      label: 'English',
    },
    {
      value: 'es',
      label: 'Español',
    },
];

const currentCountryCode = [
    {
        value: '34',
        label: '34',
    },
];


const Profile : React.FC<Prop> = () => {

    const classes = useStyles();

    const profile: ProfileDataPostal = useProfile()
    const snackbarDispatch = useSnackbarDispatchContext()

    const sendForm = async (values : ProfileDataPostal, { setSubmitting } : FormikHelpers<ProfileDataPostal>) =>{
        console.log("enviando values: " + values)
        apiPost(URL_UPDATE_PROFILE, values)                  
        //apiPost('http://s3-ceatic.ujaen.es:8013/api/auth/updateProfile', values)
            .then(data => {
                console.log(data)
                snackbarDispatch(add({ message: "Tus datos han sido actualizados", severity: "success"}))
            })
            .catch(error => snackbarDispatch(add({ message: error.message, severity: "error"})))
    } 

    //TODO cambiar funcion
    const formik = useFormik({
        initialValues: profile,
        validationSchema: ProfileSchema,
        enableReinitialize: true,
        onSubmit: sendForm
    })

    return (
        <div>
            <div style={{display: 'flex',  justifyContent:'center', alignItems:'center'}}>
                <h1> Perfil </h1>
            </div>
            <form onSubmit={formik.handleSubmit} className={classes.root} noValidate autoComplete="off">
                <Grid>
                    <TextField
                        id="streetAddress"
                        name="streetAddress"
                        label="Dirección"
                        value={formik.values.streetAddress}
                        onChange={formik.handleChange}
                        error={formik.touched.streetAddress && Boolean(formik.errors.streetAddress)}
                        helperText={formik.touched.streetAddress && formik.errors.streetAddress}
                        type="text"
                        variant="outlined"
                    />
                    <TextField
                        disabled
                        id="email"
                        name="email"
                        label="Email"
                        value={formik.values.email}
                        type="email"
                        variant="outlined"
                    />
                    <TextField
                        id="hash_pwd"
                        name="hash_pwd"
                        label="Contraseña"
                        type="text"
                        value={formik.values.hash_pwd}
                        onChange={formik.handleChange}
                        error={formik.touched.hash_pwd && Boolean(formik.errors.hash_pwd)}
                        helperText={formik.touched.hash_pwd && formik.errors.hash_pwd}
                        variant="outlined"
                    />
                    
                    <TextField
                        id="postalCode"
                        name="postalCode"
                        label="Código postal"
                        value={formik.values.postalCode}
                        onChange={formik.handleChange}
                        error={formik.touched.postalCode && Boolean(formik.errors.postalCode)}
                        helperText={formik.touched.postalCode && formik.errors.postalCode}
                        type="text"
                        variant="outlined"
                    />
                    <TextField
                        id="country"
                        select
                        label="País"
                        name="country"
                        value={formik.values.country}
                        onChange={formik.handleChange}
                        error={formik.touched.country && Boolean(formik.errors.country)}
                        helperText="Selecciona tu país"
                        variant="outlined"
                    >
                        {currentCountry.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                            {option.label}
                            </MenuItem>
                        ))}
                    </TextField>
                   
                    <TextField
                        id="language"
                        select
                        label="Idioma"
                        name="language"
                        value={formik.values.language}
                        onChange={formik.handleChange}
                        error={formik.touched.language && Boolean(formik.errors.language)}
                        helperText="Selecciona tu país"
                        variant="outlined"
                    >
                        {currentLanguage.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                            {option.label}
                            </MenuItem>
                        ))}
                    </TextField>
                    <TextField
                        id="pre_telephone"
                        select
                        label="Sufijo telefónico"
                        name="pre_telephone"
                        value={formik.values.pre_telephone}
                        onChange={formik.handleChange}
                        error={formik.touched.pre_telephone && Boolean(formik.errors.pre_telephone)}
                        helperText="Selecciona tu Sufijo"
                        variant="outlined"
                    >
                        {currentCountryCode.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                            {option.label}
                            </MenuItem>
                        ))}
                    </TextField>
                    <TextField
                        id="telephone"
                        name="telephone"
                        label="Teléfono"
                        value={formik.values.telephone}
                        onChange={formik.handleChange}
                        error={formik.touched.telephone && Boolean(formik.errors.telephone)}
                        helperText={formik.touched.telephone && formik.errors.telephone}
                        type="text"
                        variant="outlined"
                    />
                    <TextField
                        id="locality"
                        name="locality"
                        label="Localidad"
                        value={formik.values.locality}
                        onChange={formik.handleChange}
                        error={formik.touched.locality && Boolean(formik.errors.locality)}
                        helperText={formik.touched.locality && formik.errors.locality}
                        type="text"
                        variant="outlined"
                    />
                    <TextField
                        disabled
                        id="link"
                        name="link"
                        label="Link"
                        value={formik.values.link}
                        type="text"
                        variant="outlined"
                    />
                </Grid>
                <br />
                <Grid container direction="row" justifyContent="center" alignItems="center">
                    <Button variant="contained" color="primary" type="submit">
                        Actualizar
                    </Button>
                </Grid>
            </form>
            <br/>
            <div style={{textAlign: 'center'}}>
                {formik.values.qr === '' ?  <img src={URL_PRODUCT_PICTURE + "default.png"} text-align="center" height="400px"/> : <img src={URL_QR + formik.values.qr} text-align="center" height="400px"/>}
            </div>    
        </div>
    );
}

export default Profile