import React from 'react'
import { useHistory } from "react-router-dom"
import signinSchema from '../schema/SigninSchema';
import { authenticate } from '../../utils/session'
import { useSessionDispatchContext } from '../../contexts/SessionContext'
import { login } from '../../actions/Session'
import { useSnackbarDispatchContext } from '../../contexts/SnackbarContext'
import { add } from '../../actions/Snackbar'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import { FormikHelpers, useFormik } from 'formik'
import { Button } from '@material-ui/core'
import MenuItem from '@material-ui/core/MenuItem'
import TextField from '@material-ui/core/TextField'
import Grid from '@material-ui/core/Grid'

type Prop = {

}
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& .MuiTextField-root': {
        margin: theme.spacing(1),
        width: '25ch',
      },
      textAlign: "center"
    },
  }),
);

interface SigninData {
	email : string;
    hash_pwd: string;
}

const Signin : React.FC<Prop> = () => {
    const history = useHistory()
    const classes = useStyles()
    const dispatch = useSessionDispatchContext()
    const snackbarDispatch = useSnackbarDispatchContext()

    const sendForm = async (values: SigninData, { setSubmitting } : FormikHelpers<SigninData>) =>{
        authenticate(values.email, values.hash_pwd)
        .then(data => {
            dispatch(login(data))
            history.push('/profile')
        })
        //.catch(error => console.log(typeof(error)))
        .catch(error => snackbarDispatch(add({ message: error.message, severity: "error"})))
    }
    
    const formik = useFormik({
        initialValues: {
            email : "",
            hash_pwd: "",
        },
        validationSchema: signinSchema,
        onSubmit: sendForm
    })

    return (
        <div className={classes.root}>
            <h1>Identifícate</h1>
                <form onSubmit={formik.handleSubmit} className={classes.root} noValidate>
                    <Grid>
                        <TextField 
                            className="form-control"
                            name="email"
                            placeholder=""
                            type="text"
                            label="Email"
                            value={formik.values.email}
                            variant="outlined"
                            onChange={formik.handleChange}
                            error={formik.touched.email && Boolean(formik.errors.email)}
                            helperText={formik.touched.email && formik.errors.email}
                        ></TextField>
                    </Grid>
                    <Grid>
                        <TextField 
                            className="form-control"
                            name="hash_pwd"
                            placeholder=""
                            type="password"
                            label="Contraseña"
                            value={formik.values.hash_pwd}
                            variant="outlined"
                            onChange={formik.handleChange}
                            error={formik.touched.hash_pwd && Boolean(formik.errors.hash_pwd)}
                            helperText={formik.touched.hash_pwd && formik.errors.hash_pwd}
                        ></TextField>
                    </Grid>
                    <br/>
                    <Button
                        color="primary"
                        variant="contained"
                        className="mr-1 mb-1 btn-block"
                        type="submit"
                        >
                            Identificate
                    </Button>
                </form>
        </div>
    )
}

export default Signin