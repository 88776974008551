import * as Yup from 'yup'

const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

//Esquema para crear una s__PostalAddress 
const ProfileSchema = Yup.object().shape({
    streetAddress: Yup.string()
        .min(2, 'demasiado corto')
        .max(50, 'Demasiado largo')
        .required('Requerido'),
    postalCode : Yup.string()
        .min(2, 'demasiado corto')
        .max(50, 'Demasiado largo')
        .required('Requerido'),
    locality : Yup.string()
        .min(2, 'demasiado corto')
        .max(50, 'Demasiado largo')
        .required('Requerido'),
    country : Yup.string()
        .required('Requerido'),
    email: Yup.string()
        .email('Email invalido')
        .required('Requerido'),
    telephone: Yup.string()
        .matches(phoneRegExp,'Número de telefono inválido')
        .required('Requerido'),
    hash_pwd: Yup.string() 
        .min(2, 'demasiado corto')
        .max(50, 'Demasiado largo'), 
    language: Yup.string()
        .required('Requerido'),
    link: Yup.string()
        .required('Requerido'),
});


export default ProfileSchema;