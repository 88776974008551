import React, { useCallback, useReducer, useContext } from 'react'
import {Session} from '../types/Session'
import sessionReducer from '../reducers/Session'
import { keyToken } from '../utils/constants'

const savedPreviousToken = localStorage.getItem(keyToken)
const initialValue : Session = {
    authToken: savedPreviousToken|| '',
    user: null
}

const SessionStateContext = React.createContext<Session>(initialValue)
const SessionDispatchContext = React.createContext<React.Dispatch<any>|any>({})

type Prop = {
    children: React.ReactNode
}

const SessionContext : React.FC<Prop> = ({children}) =>{
    const reducer = useCallback(sessionReducer ,[])
    const [state, dispatch] = useReducer(reducer, initialValue)
    return (
        <SessionDispatchContext.Provider value={dispatch}>
            <SessionStateContext.Provider value={state}>
                {children}
            </SessionStateContext.Provider>
        </SessionDispatchContext.Provider>
    )
}

const useSessionDispatchContext = () => {
    const dispatch = useContext(SessionDispatchContext)
    if(!dispatch)
        throw Error("Must set dispatch provider")
    return dispatch
}

const useSessionStateContext = () => {
    const state = useContext<Session>(SessionStateContext)
    if(!state)
        throw Error("Must set dispatch provider")
    return state
}
export default SessionContext

export {
    useSessionDispatchContext,
    useSessionStateContext
}
