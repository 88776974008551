/* Formulario para crear una entidad gr__BusinessEntity, que además nos permitira ir a asociarlo
*  a una tienda física o simplemente crear negocio.
*/

import React from 'react'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { FormikHelpers, useFormik } from 'formik'
import { FormGroup, Row, Col } from 'reactstrap'
import BusinessCreateSchema from '../schema/BusinessCreateSchema'
import { apiPost } from '../../utils/api'
import { useHistory } from "react-router-dom"
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import { URL_INSERT_BUSINESS } from '../../utils/constants'
import { useSnackbarDispatchContext } from '../../contexts/SnackbarContext'
import { add } from '../../actions/Snackbar'

import { Button } from '@material-ui/core'
import MenuItem from '@material-ui/core/MenuItem'
import TextField from '@material-ui/core/TextField'
import Grid from '@material-ui/core/Grid'

interface ChildComponentProps extends RouteComponentProps<any> {
    /* other props for ChildComponent */
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& .MuiTextField-root': {
        margin: theme.spacing(1),
        width: '25ch',
      },
      textAlign: "center"
    },
  }),
);

interface BusinessData {
	name : string;
    gr__category: string;
    description: string;
}

const BusinessCreate : React.FC<ChildComponentProps> = ({}) => {
    const history = useHistory()
    const classes = useStyles()
    const snackbarDispatch = useSnackbarDispatchContext()

    const sendForm = async (values: BusinessData, { setSubmitting } : FormikHelpers<BusinessData>) =>{
        apiPost(URL_INSERT_BUSINESS, values)                  
        //apiPost('http://s3-ceatic.ujaen.es:8013/api/business/insertBusiness', values)
        .then(data => {
            console.log(data)
            history.push(`/business/${values.name}/postalAdress`)
        })
        .catch(error => snackbarDispatch(add({ message: error.message, severity: "error"})))
    }

    const formik = useFormik({
        initialValues: {
            name : "",
            gr__category: "",
            description: ""
        },
        validationSchema: BusinessCreateSchema,
        enableReinitialize: true,
        onSubmit: sendForm
    })

    return (
        <div className={classes.root}>
            <h1>Formulario para registrar un negocio</h1>


                <form onSubmit={formik.handleSubmit} className={classes.root} noValidate autoComplete="off">
                    <Grid >
                        <TextField 
                            label="Nombre negocio"
                            className="form-control"
                            name="name"
                            placeholder=""
                            type="text"
                            variant="outlined"
                            value={formik.values.name}
                            onChange={formik.handleChange}
                            error={formik.touched.name && Boolean(formik.errors.name)}
                            helperText={formik.touched.name && formik.errors.name}
                        ></TextField>
                        
                    </Grid>
                    <Grid>
                        <TextField 
                            id="gr__category"
                            select
                            label="Tipo de negocio"
                            name="gr__category"
                            variant="outlined"
                            value={formik.values.gr__category}
                            onChange={formik.handleChange}
                            error={formik.touched.gr__category && Boolean(formik.errors.gr__category)}
                            helperText={formik.touched.gr__category && formik.errors.gr__category}
                            >
                            <MenuItem key="greengrocer" value="greengrocer">
                                Frutería
                            </MenuItem>
                            <MenuItem key="butcher" value="butcher">
                                Carniceria
                            </MenuItem>
                            <MenuItem key="fishmonger" value="fishmonger">
                                Pescaderia
                            </MenuItem>
                        </TextField>
                    </Grid>
                    <Grid>
                        <TextField
                            label="Descripción"
                            className="form-control"
                            name="description"
                            placeholder=""
                            type="text"
                            variant="outlined"
                            value={formik.values.description}
                            onChange={formik.handleChange}
                            error={formik.touched.description && Boolean(formik.errors.description)}
                            helperText={formik.touched.description && formik.errors.description}
                        ></TextField>
                    </Grid>
                    <br/>
                    <Row>
                        <Col lg={12} md={12}>
                            <Button 
                            color="primary"
                            variant="contained"
                            className="mr-1 mb-1 btn-block"
                            type="submit"
                            >
                                Siguiente
                            </Button>
                        </Col>
                    </Row>
                </form>

        </div>
    )
}

export default withRouter(BusinessCreate)